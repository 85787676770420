.main-container {
    margin: -100px auto 0 auto;
}

/* --- LANDING PAGE --- */

#home {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.landing-page-details {
    margin: auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.landing-page-socials ul {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.landing-page-socials ul li a i {
    font-size: 1.5em;
    padding: 15px 10px;
    margin: 0;
}

.small-title {
    text-align: left;
    font-size: 1.05em;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 0.5em;
}

.big-title {
    margin: 0;
    text-align: left;
    font-weight: 700;
    font-size: 2.75em;
    letter-spacing: 0;
}
.medium-title  {
    text-align: left;
    font-weight: 700;
    margin:  0 ;
}
.medium-title em {
    color: #368cf6;
}

.my-name {
    letter-spacing: 0.0001em;
    text-wrap: nowrap;
    font-family: 'Fira Code', monospace;
}

h3 {
    margin: 5px 0 0 0;
    text-align: left;
}

h3 em {
    color: rgb(28, 27, 27);
    letter-spacing: 0.05em;
}

h3 a:hover {
    color: #368cf6;
    text-decoration: none;
}

.short-bio {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.short-bio p {
    margin-top: 25px;
    font-size: 1.3em;
    line-height: 1.5em;
    letter-spacing: 0.02em;
}

.cta-container {
    display: flex;
    justify-content: center;
}

.cta {
    display: flex;
    text-align: center;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 12px;
    border-radius: 10px;
    font-weight: 700;
    letter-spacing: 0.05em;
    background-color: #13800eba;
    margin-top: 50px;
    transition: all 0.2s ease;
    cursor: pointer;
    text-decoration: wavy;
    margin-left: 10px;
    color: #171717e4;
    font-weight: 600;
}

.cta img {
    height: 25px;
    width: 25px;
}

.email-button {
    border: 1px solid #13800eba;
    background-color: #13800eba;
}

.cta:hover {
    border: 1px solid #368cf6;
    background-color: #368cf6;
    padding: 10px 20px;
    color: white;
}

.art-me {
    display: flex;
    height: 100%;
}

.art-me img {
    height: auto;
    width: 450px;
    margin: 10px 5px;
}

@media only screen and (min-width: 768px) {
    .big-title {
        font-size: 3.5em;
    }

    .short-bio {
        max-width: 90%;
    }

    .art-me {
        display: block;
    }

    #home {
        flex-direction: row;
    }

    .landing-page-socials ul {
        flex-direction: column;
    }

    .landing-page-socials ul li a i {
        margin: 5px 0;
    }

    .email-container {
        margin: 0;
    }
}

@media only screen and (min-width: 1000px) {
    #home {
        gap: 10%;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    user-select: none;
    font-size: 15px;
    font-weight: 400;
    max-width: 100vw;
    background-color :transparent;
    color: #0c0c0c;
    scroll-behavior: smooth;
}

body {
    background-color: transparent;
}

h1,
h2,
h3 {
    font-family: 'Fira Mono', monospace;
}

h2 {
    margin: 0 auto;
    text-align: center;
    font-size: 1.75em;
    font-weight: 700;
}

h3 {
    margin: 0 auto;
    text-align: center;
    font-size: 1.4em;
    font-weight: 700;
    letter-spacing: 0.1em;
}

a,
span {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}

.green {
    color: #368cf6;
}

.bold {
    font-weight: 700;
}

.pink {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: #13800eba;
}

ul {
    list-style-type: none;
}

.social-link a i {
    transition: all 0.2s ease;
}

.social-link a i:hover {
    color: #13800eba;
}

.main-container {
    margin: 0 auto;
    padding: 0 25px;
    text-align: center;
    max-width: 1600px;
}

section {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-top: 8vh;
}

section .hyperlink {
    color: #13800eba;
    font-weight: 700;
    text-decoration: none;
    transition: all 0.2s ease;
}

section .hyperlink:hover {
    color: #368cf6;
}

.section-description {
    margin: 50px auto 0 auto;
    text-align: center;
    max-width: 1000px;
    line-height: 1.5em;
    letter-spacing: 0.025em;
}

.page-title {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-title img {
    height: 40px;
    width: auto;
    margin-right: 20px;
}

@media only screen and (min-width: 768px) {
    h2 {
        font-size: 2.5em;
    }

    h3 {
        font-size: 2em;
    }
}

em {
    color: #3a3a3a;
}

.projects {
    padding-top: 100px;
    max-width: 1200px;
}

.project-cards-container {
    margin-top: 50px;
    height: auto;
}

.card {
    display:  flex;
    flex-direction: column;
    color: #1D263B;
}

.project-card {
    display: block;
    color: black
}

.card:not(:last-child) {
    margin: 0 auto 2em auto;
}

.card-preview-img {
    max-width: 100%;
    border-radius: 10px 10px 0 0;
    padding: 5px;
    background-color: white;
}

.card-info {
    padding: 25px;
    background-color: #FFFFFF;
    border-radius: 0 0 10px 10px;
}

.has-no-preview .card-info {
    border-radius: 10px;
}

.title-and-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.project-title {
    font-size: 1.2em;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: #1D263B;
}

.project-links {
    display: flex;
    flex-wrap: nowrap;
}

.project-links a i {
    padding: 5px;
}

.project-links a i {
    color: #13800eba;
    font-size: 1.2em;
    transition: all 0.2s ease;
}

.project-links a i:hover {
    font-size: 1.25em;
    padding: 2px 5px;
    color: #368cf6;
}

.project-links a:last-child {
    margin-left: 5px;
}

.project-skills {
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #368cf6;
}

.project-skills span {
    color: #0FA3B1;
    font-weight: 700;
    font-size: 0.9em;
    letter-spacing: 0.05em;
}

.project-skills span:not(:last-child) {
    margin-right: 10px;
}

.project-description {
    color: #1D263B;
}

@media only screen and (min-width: 768px) {
    .project-cards-container {
        column-count: 2;
        column-gap: 2em;
    }
}

a {
    text-decoration: none;
}

header {
    margin: 0 auto;
    width: 100%;
    max-width: 1600px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    height: 70px;
    padding: 0 15px;
    z-index: 2;
}

.header-logo {
    padding: 10px;
    font-size: 1.2em;
    letter-spacing: 0.05em;
}

.nav-menu {
    list-style-type: none;
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    left: 0;
}

.nav-item {
    text-align: center;
    display: none;
}

.nav-item a {
    display: block;
    padding: 20px;
    font-weight: 700;
    width: 100vw;
    letter-spacing: 0.1em;
    transition: all 0.2s ease;
    color: #070808;
}

.nav-item a:hover,
.nav-item a:focus {
    color: #13800eba;
    text-decoration: underline;
}

.active {
    background-color: rgb(0, 0, 0);
}

.active .nav-item {
    display: block;
}

.toggle {
    padding: 1.1em;
}

.toggle i {
    font-size: 2em;
}

@media only screen and (min-width: 768px) {
    .nav {
        display: flex;
        text-align: center;
        justify-content: space-between;
        align-items: center;
    }

    .nav-menu {
        position: relative;
        align-items: center;
        flex-direction: row;
        background-color: #fbfbffa0;
        height: auto;
        height: auto;
    }

    .nav-item {
        position: relative;
        display: block;
        height: auto;
        width: auto;
        margin: 0;
    }

    .nav-item a {
        width: -moz-fit-content;
        width: fit-content;
        color: #FFFFFF;
    }

    .toggle {
        display: none;
    }
}

.footer-container {
    border-top: 1px solid #368cf6;
    margin: 50px auto;
    padding-top: 50px;
}

.footer {
    margin: 0 auto;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;
}

.footer-column {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    align-self: flex-start;
}

.footer-logo {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 5px;
    text-decoration: wavy;
    letter-spacing: 0.05em;
}

.socials {
    margin: 15px 0;
}

.socials ul {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    text-align: center;
    margin: 15px 0;
}

.socials ul li:not(:last-child) {
    margin-right: 15px;
}

.socials ul li a i {
    font-size: 1.8em;
}

.copyright {
    margin-top: 6px;
    letter-spacing: 0.15em;
}

.footer-button {
    display: flex;
    text-align: center;
    align-items: center;
    text-decoration: wavy;
    letter-spacing: 0.05em;
    transition: all 0.2s ease;
    font-weight: 500;
}

.footer-button:hover {
    text-decoration: dashed;
    color: #98f3297a;
}

.footer-button:not(:last-child) {
    margin-bottom: 15px;
}

.footer-button img {
    margin-right: 12px;
}

@media only screen and (min-width: 768px) {
    .footer {
        margin: 0 auto;
        padding: 0 25px;
        flex-direction: row;
        justify-content: space-between;
        max-width: 1600px;
    }
}

h4,
h5 {
    font-weight: 700;
    letter-spacing: 0.05em;
}

.date {
    font-style: italic;
}

/* --- PROFILE --- */.profile-container {
    margin-top: 25px;
    display: block;
    flex-direction: column;
    align-items: center;
}

.me-icon-container {
    width: 200px;
    height: auto;
    margin: 50px auto 0 auto;
    object-fit:  contain;
    margin-bottom: 25px;
    text-align: center;
}

.me-icon {
    height: 100%;
    width: 100%;
}

.terminal-container {
    max-width: 800px;
    width: 100%;
    margin-top: 25px;
}

.terminal-header {
    padding: 5px 20px;
    display: flex;
    text-align: center;
    justify-content: space-between;
    border-radius: 10px 10px 0 0;
    background-color: #98f329da;
}

#terminal-title {
    font-weight: 700;
    color: #1D263B;
    letter-spacing: 0.1em;
}

.right-side-buttons i {
    color: #050609;
}

.right-side-buttons i:not(:last-child) {
    margin-right: 10px;
}

.terminal-window {
    padding: 20px;
    border-radius: 0 0 10px 10px;
    text-align: left;
    border: 1px solid #00000077;
}

.terminal-window div,
.terminal-window div p {
    font-family: 'Fira Code', monospace;
    letter-spacing: 0.02em;
}

.terminal-window div:not(:last-child) {
    margin-bottom: 20px;
}

.terminal-window div p {
    margin-bottom: 0.25em;
}

.input::before {
    content: ">\A0";
    color: #368cf6;
    font-weight: 700;
}

.bottom span {
    content: "\A0";
    border-right: 12px solid;
    animation: blink 1s steps(1) infinite;
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}

/* --- EDUCATION & EXPERIENCE --- */


.education-experience-container h3 {
    margin-bottom: 50px;
}

#experience {
    margin-top: 50px;
}

.education-experience-card {
    margin-top: 25px;
    width: max-content;
}

.card-info p {
    margin-top: 0.35em;
    width: 500px;
}

.card-info p a:hover {
    color: #13800eba;
}

.card-description {
    margin: 15px 0 0 15px;
}

.card-description p:not(:first-child) {
    margin-top: 0.35em;
}

.card-description p::before {
    content: ">\A0";
    color: #368cf6;
    font-weight: 700;
}

.card-description h5:not(:first-child) {
    margin-top: 10px;
}

/* --- TECH STACK --- */

.tech-stack-container {
    margin: 50px auto 0 auto;
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.skill {
    border-radius: 12px;
    margin: 10px;
    border: 2px solid #368cf6;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    text-align: center;
}

.skill span {
    margin-left: 10px;
}

/* --- ALL SOCIAL MEDIA --- */

.social-media-list {
    margin: 50px auto 0 auto;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.social-media-item {
    display: flex;
    text-align: center;
    align-items: center;
    padding: 5px 15px;
}

.social-media-item:hover {
    color: #13800eba;
}

.social-media-item i {
    margin-right: 15px;
    font-size: 1.5em;
}

.social-media-item:not(:last-child) {
    margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
    .profile-container {
            margin: 50px auto 0 auto;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 70%;
        }
    
        .terminal-container {
            flex-grow: 1;
            margin: 0;
        }
    
        .me-icon-container {
            width: 300px;
            height: auto;
        }

    .education-experience-card {
        display: flex;
        justify-content: space-between;
    }

    .card-info {
        width: 40%;
    }

    .card-description {
        width: 60%;
        margin-top: 0;
    }

    .section-description {
        margin: 50px auto 0 auto;
    }
}

.disabled {
    text-decoration: line-through;
}
